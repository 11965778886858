import { EnvelopeIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Snap2Passport from '../../public/Snap2Passport.webp';

const navigation = {
  company: [
    { name: 'Documents', href: '/#doc_types' },
    { name: 'Blog', href: '/blog' },
  ],
  legal: [
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms' },
  ],
};

export default function Example() {
  return (
    <footer className="bg-white mb-12" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto pb-6 max-w-7xl px-6 lg:px-8 border-t">
        <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div className="flex flex-col jus">
              <Image
                className="w-[133px] h-[40px]"
                src={Snap2Passport}
                alt="snap2pass creates passport photos online"
              />
              <p className=" text-gray-600">California, USA</p>
              <a href="mailto:info@snap2pass.com">
                <div className="flex items-start pt-5">
                  <div className="flex-shrink-0">
                    <EnvelopeIcon
                      className="mx-auto h-6 w-6 flex-shrink-0 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-3  leading-6 text-gray-600">
                    info@snap2pass.com
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8 ml-10">
            <div>
              <h3 className=" font-semibold leading-6 text-gray-900">
                Company
              </h3>
              <ul role="list" className="mt-6 space-y-4">
                {navigation.company.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className=" leading-6 text-gray-600 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-10 md:mt-0">
              <h3 className=" font-semibold leading-6 text-gray-900">Legal</h3>
              <ul role="list" className="mt-6 space-y-4">
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className=" leading-6 text-gray-600 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
