import { Button } from '@/components/ui/button';
import { Camera, Menu } from 'lucide-react';
import Link from 'next/link';
import { useState } from 'react';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { name: 'Documents', href: '/#doc_types' },
    { name: 'FAQ', href: '/#faq' },
    { name: 'Blog', href: '/blog' },
    { name: 'Business API', href: '/business' },
  ];

  return (
    <header className="bg-white shadow-lg z-50 sticky top-0">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex items-center">
            <Link href="/" aria-label="Home" className="flex items-center">
              <Camera className="h-8 w-8 text-indigo-600" />
              <span className="ml-2 text-xl font-bold">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-600">Snap2</span>
                <span className="text-black">Pass</span>
              </span>
            </Link>
          </div>
          <div className="hidden md:flex space-x-4">
            {navItems.map((item) => (
              <Button
                key={item.name}
                variant="ghost"
                className="text-gray-600 hover:text-indigo-600 transition-colors duration-200"
                asChild
              >
                <Link href={item.href}>{item.name}</Link>
              </Button>
            ))}
          </div>
          <div className="md:hidden">
            <Button variant="ghost" onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <Menu className="h-6 w-6" />
            </Button>
          </div>
        </div>
      </nav>
      {isMenuOpen && (
        <div className="md:hidden">
          {navItems.map((item) => (
            <Button
              key={item.name}
              variant="ghost"
              className="w-full text-left text-gray-600 hover:text-indigo-600 transition-colors duration-200"
              asChild
            >
              <Link href={item.href}>{item.name}</Link>
            </Button>
          ))}
        </div>
      )}
    </header>
  );
}
